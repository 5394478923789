<template>
  <section class="pb-2">
    <div class="tb-card tb-flex-vertical">
      <a class="tb-page-title">Race List ({{ pageArrayStock.length }})</a>
    </div>
    <div class="tb-card mt-2">
      <table class="tb-table">
        <thead>
          <tr>
            <th style="width: 5%">
              <input type="text" class="form-control" placeholder="#" disabled readonly>
            </th>
            <th style="width: 20%"><input v-model="filterTrackName" type="text" class="form-control"
                placeholder="Track Name"></th>
            <th style="width: 20%"><input v-model="filterRaceStatus" type="text" class="form-control"
                placeholder="Race Status"></th>
            <th style="width: 20%"><input v-model="filterTotalBet" type="text" class="form-control"
                placeholder="Total Bet"></th>
            <th style="width: 20%">
              <date-picker v-model="datePickerShow" class="ua-h-40" range confirm confirm-text="Seçilen Aralığı Listele"
                @confirm="handlePicker" format="DD-MM-YYYY" />
            </th>
            <th style="width: 15%">
              <input type="text" class="form-control" placeholder="Options" disabled readonly>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in pageArray">
            <td><a>{{ index + 1 }}</a></td>
            <td><a>{{ item.track.trackName }}</a></td>
            <td><a>{{ item.raceStatus }}</a></td>
            <td><a>{{ item.totalBet }}</a></td>
            <td><a>{{ $moment(new Date(item.createdAt)).format('YYYY-MM-DD') }}</a></td>
            <td>
              <button class="tb-button bg-primary" @click="tableInteractive(item, index, 'edit')">
                <span class="text-white">Edit</span>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- Pagination -->
    <div class="tb-flex-vertical mt-2">
      <button class="tb-button bg-primary py-1 px-4" @click="pagination()">
        <a class="text-white">See More</a>
      </button>
    </div>
    <!-- Edit Modal -->
    <b-modal v-if="editModal" v-model="editModal" size="xl" hide-header hide-footer centered>
      <div class="col-12 text-center">
        <a class="tb-page-title">{{ editObject.track.trackName }}</a>
        <hr>
      </div>

      <div class="row py-1">
        <div class="d-flex col-12">
          <div class="col-3 ">
            <div class="col-12">
              <a class="tb-sub-title">Track Detail</a>
              <hr>
            </div>
            <table style="width:100%">
              <tr>
                <th class="py-1">Track Name:</th>
                <td>{{ editObject.track.trackName }}</td>
              </tr>
              <tr>
                <th class="py-1">Total Bet:</th>
                <td>{{ editObject.totalBet }}</td>
              </tr>
              <tr>
                <th class="py-1">Track Lap:</th>
                <td>{{ editObject.track.trackLap }}</td>
              </tr>
              <tr>
                <th class="py-1">Total Limit:</th>
                <td>{{ editObject.track.trackLimit }}</td>
              </tr>
              <tr>
                <th class="py-1">Race Status:</th>
                <td>{{ editObject.raceStatus }}</td>
              </tr>
            </table>
          </div>
          <div class="col row">
            <div class="col-12 text-center">
              <a class="tb-sub-title">Races Players</a>
              <hr>
            </div>
            <div class="col-4 p-1" v-for="(item, index) in editObject.racePlayers"
              :key="`${item.user.userFirstname}-${index}`">
              <div v-if="item.user" class="col-12 tb-border-color-light tb-border-radius-0-5-rem p-2">
                <table style="width:100%">
                  <tr>
                    <th>User Name:</th>
                    <td>{{ item.user.userFirstname }} {{ item.user.userLastname }}
                    </td>
                  </tr>
                  <tr>
                    <th>User Language:</th>
                    <td>{{ item.user.userLanguage }}</td>
                  </tr>
                  <tr>
                    <th>User Birthday:</th>
                    <td>{{ item.user.userBirthDate }}</td>
                  </tr>
                  <tr v-if="item.car">
                    <th>Car Brand Name:</th>
                    <td>{{ item.car.brand.brandName }}</td>
                  </tr>
                  <tr v-if="item.car">
                    <th>Car Model Name:</th>
                    <td>{{ item.car.carModel.modelName }}</td>
                  </tr>
                </table>
                <div>
                  <Crown />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 text-center">
        <hr>
        <b-button variant="outline-primary" @click="saveData('update')">Close</b-button>
      </div>
    </b-modal>

    <!-- <div class="col-12">
          <label>Track Image</label>
          <input id="updateTrackImage" type="file" class="form-control">
        </div> -->
  </section>
</template>

<script>
import { BCard, BButton, BModal } from 'bootstrap-vue'

import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/locale/tr'
import 'vue2-datepicker/index.css'
import Crown from '@/assets/images/icons/Crown.vue'

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    BCard,
    BButton,
    BModal,
    DatePicker,
    Crown,
  },
  data() {
    return {
      pageArrayStock: [], // for Frontend Search
      pageArray: [],
      page: 0,

      editTransferDate: '',
      datePicker: [],
      datePickerShow: [],

      editModal: false,
      editObject: {},

      filterTrackName: '',
      filterRaceStatus: '',
      filterTotalBet: '',
      filterCreatedAt: '',
    }
  },
  watch: {
    filterTrackName: {
      handler() {
        this.filterNow()
      },
    },
    filterRaceStatus: {
      handler() {
        this.filterNow()
      },
    },
    filterTotalBet: {
      handler() {
        this.filterNow()
      },
    },
    filterCreatedAt: {
      handler() {
        this.filterNow()
      },
    },
  },
  mounted() {
    const oneDayLater = new Date()
    this.datePickerShow.push(new Date(`${this.$moment.utc().format('YYYY-MM-DD')}T00:00:10.000Z`))
    this.datePickerShow.push(new Date(`${this.$moment.utc(oneDayLater).format('YYYY-MM-DD')}T23:59:00.000Z`))
    this.handlePicker([new Date(), oneDayLater])
  },
  methods: {
    async handlePicker(value) {
      this.datePicker[0] = await this.$moment(new Date(value[0])).format('YYYY-MM-DD')
      this.datePicker[1] = await this.$moment(new Date(value[1])).format('YYYY-MM-DD')
      await this.mountedData()
    },
    async mountedData() {
      // Clear Variables..
      this.editModal = false

      this.filterTrackName = ''
      this.filterRaceStatus = ''
      this.filterTotalBet = ''
      this.filterCreatedAt = ''

      // Get List
      await this.$http.get(`/race/list/${this.datePicker[0]}T00:00:10.000Z/${this.datePicker[1]}T23:59:00.000Z?limit=100&page=${this.page}`)
        .then(res => {
          // eslint-disable-next-line no-multi-assign, no-undef
          this.pageArrayStock = this.pageArray = res.data
          // eslint-disable-next-line no-alert
        }).catch(() => { alert('ERROR! PLEASE CONTACT TO DEVELOPER') })
    },
    async tableInteractive(item, index, type) {
      if (type === 'edit') {

        await this.$http.get(`/race/detail/${item.raceId}`)
          .then(res => {
            // eslint-disable-next-line no-multi-assign, no-undef
            this.editObject = { ...res.data }
            //   this.pageArrayStock = this.pageArray = res.data
            // eslint-disable-next-line no-alert
          }).catch(() => { alert('ERROR! PLEASE CONTACT TO DEVELOPER') })
        // eslint-disable-next-line no-undef
        this.editModal = true

      }
    },
    saveData() {
      this.editModal = false
    },
    async pagination() {
      // eslint-disable-next-line no-plusplus
      await this.$http.get(`/race/list/2020-01-01T00:00:00.000Z/2023-07-07T23:00:00.000Z?limit=100&page=${this.page++}`)
        .then(res => {
          // eslint-disable-next-line no-multi-assign, no-undef
          this.pageArrayStock = this.pageArray.push(...res.data)
          // eslint-disable-next-line no-alert
        }).catch(() => { alert('ERROR! PLEASE CONTACT TO DEVELOPER') })
    },
    async filterNow() {
      this.pageArray = this.pageArrayStock.filter(x => x.track.trackName.toLowerCase().includes(this.filterTrackName.toLowerCase()) && x.raceStatus.toString().toLowerCase().includes(this.filterRaceStatus.toLowerCase()) && x.totalBet.toString().toLowerCase().includes(this.filterTotalBet.toLowerCase()))
    },
  },
}
</script>
